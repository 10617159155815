/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */

import { React, useState } from "react";
import { setTokenToSession, clearPrevSession } from "./Utils";

export function LoginAs({ setIsLoading }) {
  const [userEntered, setUserEntered] = useState("");

  const handleLoginAsUserChange = (event) => {
    event.preventDefault();
    setUserEntered(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);
    fetch("/gm/api/login_as?id=" + userEntered, {
      method: "POST",
    })
      .then((resp) => {
        if (resp.status == 200) {
          return resp.json();
        } else {
          // clear prev stored session and force a login via google
          // note that unlike logout(), we didn't sign out google here so any valid google
          // signin sessions will continue to work by performing a login request for the
          // user automatically.
          clearPrevSession();
          window.location.replace(window.location.origin);
        }
      })
      .then((resp) => {
        console.log(resp.token);

        clearPrevSession();
        setTokenToSession(resp.token);
        window.location.replace(window.location.origin);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="box center">
      <div className="frame">
        <h1>Login As</h1>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__group field">
            <input
              type="input"
              className="form__field"
              placeholder="User ID"
              name="code"
              id="code"
              value={userEntered}
              required
              autoFocus
              onChange={handleLoginAsUserChange}
            />
            <label htmlFor="name" className="form__label">
              User ID
            </label>
          </div>

          <div hidden={!userEntered}>
            <a className="btn primary" onClick={handleSubmit}>
              <span>LOGIN</span>
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}
