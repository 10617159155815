/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import "./css/Loadout.css";
import coinsLogo from "./static/icons8-coin-64.png";
import { LoadoutPreview } from "./LoadoutPreview";
import { delay } from "./Utils";

import { React, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";

export const AttachmentTypes = {
  Muzzle: "Muzzle",
  HandGuard: "HandGuard",
  Foregrip: "Foregrip",
  Optic: "Optic",
  PistolGrip: "PistolGrip",
  Stock: "Stock",
  Magazine: "Magazine",
  UpperReceiver: "UpperReceiver",
  Tactical: "Tactical",
  LowerReceiver: "LowerReceiver",
  SideOptic: "SideOptic",
  Trigger: "Trigger",
  AmmoType: "AmmoType",
  Hammer: "Hammer",
  Barrel: "Barrel",
};

export function Loadout({ logout, playerInfo, setIsLoading }) {
  const [showPreview, setShowPreview] = useState(!isMobile);

  const [armory, setArmory] = useState(null);

  // selection
  const [weapon, setWeapon] = useState("");
  const [skin, setSkin] = useState("");

  const [attachType1, setAttachType1] = useState(null);
  const [attachType2, setAttachType2] = useState(null);
  const [attachType3, setAttachType3] = useState(null);
  const [attachType4, setAttachType4] = useState(null);
  const [attachType5, setAttachType5] = useState(null);
  const [attachType6, setAttachType6] = useState(null);

  const [attach1, setAttach1] = useState(null);
  const [attach2, setAttach2] = useState(null);
  const [attach3, setAttach3] = useState(null);
  const [attach4, setAttach4] = useState(null);
  const [attach5, setAttach5] = useState(null);
  const [attach6, setAttach6] = useState(null);

  const [maxAttach, setMaxAttach] = useState(0);
  const [disabledAttachType, setDisabledAttachType] = useState([]);

  const [pendingAttachBox, setPendingAttachBox] = useState(0);
  const [cost, setCost] = useState(0);

  useEffect(() => {
    if (playerInfo) {
      fetch("/api/get/armory")
        .then((response) => response.json())
        .then((data) => {
          setArmory(data);
          const armory = data;
          const loadout = playerInfo.loadout_picked;
          // console.log("loadout", loadout);
          const weapon_selected = armory.find(
            (e) => e._id.$oid == loadout.weapon._id.$oid
          );
          const supported_attach = weapon_selected.supported_attachments;
          const supported_attached_type = Object.keys(supported_attach);
          const all_attachment = Object.keys(supported_attach)
            .map(function (type) {
              return supported_attach[type];
            })
            .flat();

          // load player loadout
          let current_picked = 0;
          Object.entries(AttachmentTypes).map((e) => {
            const type = e[1];
            const attachment = loadout.attachments[type];
            if (!attachment) {
              return;
            }

            const attach_picked = all_attachment.find(
              (e) => e._id.$oid == attachment._id.$oid
            );
            // console.log("attach", attach_picked);
            if (
              attach_picked &&
              attach_picked.name != "Default" &&
              attach_picked.name != "Empty"
            ) {
              current_picked += 1;
              if (current_picked == 1) {
                setAttachType1(type);
                setAttach1(attach_picked._id.$oid);
              }
              if (current_picked == 2) {
                setAttachType2(type);
                setAttach2(attach_picked._id.$oid);
              }
              if (current_picked == 3) {
                setAttachType3(type);
                setAttach3(attach_picked._id.$oid);
              }
              if (current_picked == 4) {
                setAttachType4(type);
                setAttach4(attach_picked._id.$oid);
              }
              if (current_picked == 5) {
                setAttachType5(type);
                setAttach5(attach_picked._id.$oid);
              }
              if (current_picked == 6) {
                setAttachType6(type);
                setAttach6(attach_picked._id.$oid);
              }

              // Lock conflicted attachment types
              const get_default_id = (type) =>
                supported_attach[type].find(
                  (a) => a.name == "Default" || a.name == "Empty"
                )._id.$oid;
              if (attach_picked.conflicting_types) {
                attach_picked.conflicting_types.map((c_type) => {
                  const default_id = get_default_id(c_type);
                  disabledAttachType.push(c_type);
                });
              }
            }
          });
          setDisabledAttachType(disabledAttachType);
        })
        .catch((e) => {
          console.log(e);
        });

      const loadout = playerInfo.loadout_picked;
      setWeapon(loadout.weapon._id.$oid);
      setSkin(loadout.skin._id.$oid);
    }
  }, [playerInfo]);

  if (!armory) {
    return;
  }

  // =========================== VARS ===========================
  // console.log("c_type", disabledAttachType);
  const weapon_selected = armory.find((e) => e._id.$oid == weapon);
  const supported_attach = weapon_selected.supported_attachments;
  // console.log("supported_attach", supported_attach);
  const all_attachment = Object.keys(supported_attach)
    .map(function (type) {
      return supported_attach[type];
    })
    .flat();
  const supported_attached_type = Object.keys(supported_attach);
  if (Math.min(supported_attached_type.length, 6) != maxAttach) {
    setMaxAttach(Math.min(supported_attached_type.length, 6));
  }
  const get_default_id = (type) =>
    supported_attach[type].find((a) => a.name == "Default" || a.name == "Empty")
      ._id.$oid;

  // cost
  const player_armory = playerInfo.armory;
  let player_armory_for_weapon = null;
  let player_unlocked_attach = null;

  let current_cost = 0;
  // weapon
  if (weapon && (!player_armory || !(weapon in player_armory))) {
    current_cost += weapon_selected.cost;
  } else {
    player_armory_for_weapon = player_armory[weapon];
    player_unlocked_attach = Object.values(player_armory_for_weapon.attachments)
      .flat()
      .map((a) => a.$oid);
  }
  // console.log("player armory", player_armory);
  // console.log("player_weapon_armory", player_armory_for_weapon);
  // console.log("player_weapon attach", player_unlocked_attach);
  // skin
  if (
    skin &&
    (!player_armory_for_weapon ||
      !player_armory_for_weapon.skins.find((e) => e.$oid === skin))
  ) {
    const skin_selected = weapon_selected.skins.find(
      (e) => e._id.$oid === skin
    );
    current_cost += skin_selected.cost;
  }
  // attachments
  if (attach1) {
    if (
      !player_armory_for_weapon ||
      !player_armory_for_weapon.attachments[attachType1] ||
      !player_armory_for_weapon.attachments[attachType1].find(
        (e) => e.$oid === attach1
      )
    ) {
      current_cost += supported_attach[attachType1].find(
        (e) => e._id.$oid === attach1
      ).cost;
    }
  }
  if (attach2) {
    if (
      !player_armory_for_weapon ||
      !player_armory_for_weapon.attachments[attachType2] ||
      !player_armory_for_weapon.attachments[attachType2].find(
        (e) => e.$oid === attach2
      )
    ) {
      current_cost += supported_attach[attachType2].find(
        (e) => e._id.$oid === attach2
      ).cost;
    }
  }
  if (attach3) {
    if (
      !player_armory_for_weapon ||
      !player_armory_for_weapon.attachments[attachType3] ||
      !player_armory_for_weapon.attachments[attachType3].find(
        (e) => e.$oid === attach3
      )
    ) {
      current_cost += supported_attach[attachType3].find(
        (e) => e._id.$oid === attach3
      ).cost;
    }
  }
  if (attach4) {
    if (
      !player_armory_for_weapon ||
      !player_armory_for_weapon.attachments[attachType4] ||
      !player_armory_for_weapon.attachments[attachType4].find(
        (e) => e.$oid === attach4
      )
    ) {
      current_cost += supported_attach[attachType4].find(
        (e) => e._id.$oid === attach4
      ).cost;
    }
  }
  if (attach5) {
    if (
      !player_armory_for_weapon ||
      !player_armory_for_weapon.attachments[attachType5] ||
      !player_armory_for_weapon.attachments[attachType5].find(
        (e) => e.$oid === attach5
      )
    ) {
      current_cost += supported_attach[attachType5].find(
        (e) => e._id.$oid === attach5
      ).cost;
    }
  }
  if (attach6) {
    if (
      !player_armory_for_weapon ||
      !player_armory_for_weapon.attachments[attachType6] ||
      !player_armory_for_weapon.attachments[attachType6].find(
        (e) => e.$oid === attach6
      )
    ) {
      current_cost += supported_attach[attachType6].find(
        (e) => e._id.$oid === attach6
      ).cost;
    }
  }

  if (cost != current_cost) {
    setCost(current_cost);
  }

  // =========================== WEAPON ===========================
  const weapon_type_to_weapons = {};
  armory
    .sort((a, b) => a.cost - b.cost)
    .map((e) => {
      let weapon_name = e.name.replace(/_/g, " ");
      if (!player_armory || !(e._id.$oid in player_armory)) {
        weapon_name = "💰" + e.cost + " - " + weapon_name;
      }
      if (!weapon_type_to_weapons[e.weapon_type]) {
        weapon_type_to_weapons[e.weapon_type] = [];
      }
      weapon_type_to_weapons[e.weapon_type].push(
        <option value={e._id.$oid} key={e._id.$oid}>
          {weapon_name}
        </option>
      );
    });
  const weapon_options = Object.entries(weapon_type_to_weapons).map((e) => {
    const type = e[0];
    const options = e[1];

    return (
      <optgroup label={type} key={type}>
        {options}
      </optgroup>
    );
  });
  const weapon_selector = (
    <div className="weapon_wrapper">
      <div className="attach_box">
        <div className="attach_type">Weapon</div>
        <div className="select">
          <select
            value={weapon}
            onChange={(e) => {
              setWeapon(e.target.value);

              // Set the default skin from the weapon selected
              const default_skin_id = armory
                .find((el) => el._id.$oid == e.target.value)
                .skins.find((s) => s.name == "Default")._id.$oid;
              setSkin(default_skin_id);

              setAttach1(null);
              setAttach2(null);
              setAttach3(null);
              setAttach4(null);
              setAttach5(null);
              setAttach6(null);

              setAttachType1(null);
              setAttachType2(null);
              setAttachType3(null);
              setAttachType4(null);
              setAttachType5(null);
              setAttachType6(null);

              setDisabledAttachType([]);
            }}
          >
            {weapon_options}
          </select>
        </div>
      </div>
    </div>
  );

  // =========================== SKIN ===========================
  let skin_selector = null;
  if (weapon_selected.skins) {
    const skin_options = weapon_selected.skins
      .sort((a, b) => a.cost - b.cost)
      .filter(
        (e) =>
          e.hide === null ||
          e.hide === undefined ||
          (e.hide && e.hide === 0) ||
          (player_armory_for_weapon &&
            player_armory_for_weapon.skins.some((s) => s.$oid === e._id.$oid))
      )
      .map((e) => {
        let skin_name = e.name.replace(/_/g, " ");
        if (
          !player_armory_for_weapon ||
          !player_armory_for_weapon.skins
            .map((s) => s.$oid)
            .includes(e._id.$oid)
        ) {
          skin_name = "💰" + e.cost + " - " + skin_name;
        }
        return (
          <option value={e._id.$oid} key={e._id.$oid}>
            {skin_name}
          </option>
        );
      });
    skin_selector = (
      <div className="skin_wrapper">
        <div className="attach_box">
          <div className="attach_type">Skin</div>
          <div className="select">
            <select value={skin} onChange={(e) => setSkin(e.target.value)}>
              {skin_options}
            </select>
          </div>
        </div>
      </div>
    );
  }

  // =========================== ATTACH ===========================
  // Load selected attachments
  function AttachModifier({
    label,
    value,
    isPositiveGood,
    isAddSign = true,
    isLabelShowColor = false,
    postWords = null,
  }) {
    //Vertical Recoil, Horizontal Recoil, Recoil Snappiness, Recoil Return Speed, Terminal Shots, Reload Time, Trigger Threshold Range, RPM, Original Reload Time
    if (value == 0) return null;
    const isGood =
      (isPositiveGood && value > 0) || (!isPositiveGood && value < 0);
    const modifierClass = isGood
      ? "attach_modifier_green"
      : "attach_modifier_red";
    const labelClass = isLabelShowColor ? modifierClass : "attachment_modifier";

    return (
      <p className="attach_modifier">
        - <span className={labelClass}>{label}</span>
        <span className={modifierClass}>
          <span>{value > 0 && isAddSign ? " +" : " "}</span>
          {value}
          <span>{postWords !== null ? postWords : null}</span>
        </span>
      </p>
    );
  }
  const attach_selector_box = (idx) => {
    let type = null;
    let attach = null;
    if (idx == 1) {
      type = attachType1;
      attach = attach1;
    }
    if (idx == 2) {
      type = attachType2;
      attach = attach2;
    }
    if (idx == 3) {
      type = attachType3;
      attach = attach3;
    }
    if (idx == 4) {
      type = attachType4;
      attach = attach4;
    }
    if (idx == 5) {
      type = attachType5;
      attach = attach5;
    }
    if (idx == 6) {
      type = attachType6;
      attach = attach6;
    }

    // attach desc
    let attach_desc = attach
      ? all_attachment.find((a) => a._id.$oid == attach).desc
      : null;
    let original_reload_time = attach
      ? all_attachment.find((a) => a._id.$oid == attach).original_reload_time
      : 0;
    let attach_vertical_recoil = attach
      ? all_attachment.find((a) => a._id.$oid == attach)
          .vertical_recoil_modifier
      : 0;
    let attach_horizontal_recoil = attach
      ? all_attachment.find((a) => a._id.$oid == attach)
          .horizontal_recoil_modifier
      : 0;
    let attach_recoil_snappiness = attach
      ? all_attachment.find((a) => a._id.$oid == attach)
          .recoil_snappiness_modifier
      : 0;
    let attach_recoil_return_speed = attach
      ? all_attachment.find((a) => a._id.$oid == attach)
          .recoil_return_speed_modifier
      : 0;
    let attach_terminal_shots = attach
      ? all_attachment.find((a) => a._id.$oid == attach).terminal_shots_modifier
      : 0;
    let attach_reload_time = attach
      ? all_attachment.find((a) => a._id.$oid == attach).reload_time_modifier
      : 0;
    let attach_trigger_threshold = attach
      ? all_attachment.find((a) => a._id.$oid == attach)
          .trigger_threshold_modifier
      : 0;
    let attach_range = attach
      ? all_attachment.find((a) => a._id.$oid == attach).range_modifier
      : 0;
    let attach_rpm = attach
      ? all_attachment.find((a) => a._id.$oid == attach).rpm_modifier
      : 0;
    let attach_bullet_velocity = attach
      ? all_attachment.find((a) => a._id.$oid == attach)
          .bullet_velocity_modifier
      : 0;
    let attach_pellet_spread = attach
      ? all_attachment.find((a) => a._id.$oid == attach).pellet_spread_modifier
      : 0;
    // load attach type options
    const type_options = Object.entries(supported_attached_type).map((e) => {
      const type_opt = e[1];
      // filter out attach type with only default attachments
      if (supported_attach[type_opt].length == 1) {
        return;
      }

      // filter out types that have been selected already
      if (
        type !== type_opt &&
        (type_opt == attachType1 ||
          type_opt == attachType2 ||
          type_opt == attachType3 ||
          type_opt == attachType4 ||
          type_opt == attachType5 ||
          type_opt == attachType6)
      ) {
        return;
      }

      // handled disabled attachment options
      const is_disabled = disabledAttachType.includes(type_opt);
      return (
        <option disabled={is_disabled} value={type_opt} key={type_opt}>
          {is_disabled ? "🚫 " : ""}
          {type_opt}
        </option>
      );
    });

    let attach_options = null;
    if (type) {
      const attachments = supported_attach[type];
      attach_options = attachments
        .sort((a, b) => a.cost - b.cost)
        .map((attach) => {
          let attach_name = attach.name;
          if (attach_name == "Default" || attach_name == "Empty") {
            return (
              <option
                value={attach._id.$oid}
                key={attach._id.$oid}
                disabled
                selected
              >
                {type} Options
              </option>
            );
          } else {
            if (
              !(
                player_unlocked_attach &&
                player_unlocked_attach.includes(attach._id.$oid)
              )
            ) {
              attach_name = "💰" + attach.cost + " - " + attach_name;
            }
          }

          return (
            <option value={attach._id.$oid} key={attach._id.$oid}>
              {attach_name}
            </option>
          );
        });
    }

    const handle_attach_type_change = (e) => {
      e.preventDefault();
      const attach_type_picked = e.target.value;

      let prev_attach = null;
      if (idx == 1) {
        prev_attach = attach1;
        setAttachType1(attach_type_picked);
        setAttach1(null);
      }
      if (idx == 2) {
        prev_attach = attach2;
        setAttachType2(attach_type_picked);
        setAttach2(null);
      }
      if (idx == 3) {
        prev_attach = attach3;
        setAttachType3(attach_type_picked);
        setAttach3(null);
      }
      if (idx == 4) {
        prev_attach = attach4;
        setAttachType4(attach_type_picked);
        setAttach4(null);
      }
      if (idx == 5) {
        prev_attach = attach5;
        setAttachType5(attach_type_picked);
        setAttach5(null);
      }
      if (idx == 6) {
        prev_attach = attach6;
        setAttachType6(attach_type_picked);
        setAttach6(null);
      }
      // handle conflict
      const prev_selected = all_attachment.find(
        (attach) => attach._id.$oid == prev_attach
      );
      // if prev attach has conflict, unlock prev locked attachment types
      if (prev_selected && prev_selected.conflicting_types) {
        prev_selected.conflicting_types.map((c_type) => {
          setDisabledAttachType(disabledAttachType.filter((i) => i != c_type));
        });
      }
    };
    const handle_attach_change = (e) => {
      e.preventDefault();

      let prev_attach = null;
      const attach_picked = e.target.value;
      if (idx == 1) {
        prev_attach = attach1;
        setAttach1(attach_picked);
      }
      if (idx == 2) {
        prev_attach = attach2;
        setAttach2(attach_picked);
      }
      if (idx == 3) {
        prev_attach = attach3;
        setAttach3(attach_picked);
      }
      if (idx == 4) {
        prev_attach = attach4;
        setAttach4(attach_picked);
      }
      if (idx == 5) {
        prev_attach = attach5;
        setAttach5(attach_picked);
      }
      if (idx == 6) {
        prev_attach = attach6;
        setAttach6(attach_picked);
      }

      // handle conflict, update disabled attachtype
      const prev_selected = all_attachment.find(
        (attach) => attach._id.$oid == prev_attach
      );
      let newDisabledAttachType = disabledAttachType;
      // console.log("prev", prev_selected);
      // if prev attach has conflict, unlock prev locked slots
      if (prev_selected && prev_selected.conflicting_types) {
        prev_selected.conflicting_types.map((c_type) => {
          newDisabledAttachType = disabledAttachType.filter((i) => i != c_type);
        });
      }
      // if current attach has conflict, clear and lock conflict attachment type
      const attach_selected = all_attachment.find(
        (attach) => attach._id.$oid == attach_picked
      );
      if (attach_selected.conflicting_types) {
        attach_selected.conflicting_types.map((c_type) => {
          // lock c_type
          newDisabledAttachType.push(c_type);

          // try clear the selection for conflicting type if it exists already
          const default_id_for_the_type = get_default_id(c_type);
          if (attachType1 == c_type) {
            setAttachType1(null);
            setAttach1(null);
          }
          if (attachType2 == c_type) {
            setAttachType2(null);
            setAttach2(null);
          }
          if (attachType3 == c_type) {
            setAttachType3(null);
            setAttach3(null);
          }
          if (attachType4 == c_type) {
            setAttachType4(null);
            setAttach4(null);
          }
          if (attachType5 == c_type) {
            setAttachType5(null);
            setAttach5(null);
          }
          if (attachType6 == c_type) {
            setAttachType6(null);
            setAttach6(null);
          }
        });
      }

      // update disabled attach type in the end
      setDisabledAttachType(newDisabledAttachType);
    };

    return (
      <div className="attach_box" key={idx}>
        <div className="attach_title">
          <div className="attach_idx">{idx}</div>
          <div className="attach_type">
            <div className="select">
              <select value={type ?? ""} onChange={handle_attach_type_change}>
                <option value="" selected>
                  No Attachment
                </option>
                {type_options}
              </select>
            </div>
          </div>
        </div>
        {attach_options ? (
          <div className="select">
            <select value={attach} onChange={handle_attach_change}>
              {attach_options}
            </select>
          </div>
        ) : null}
        {attach ? (
          <div className="attach_desc_box">
            {attach_desc !== "desc" ? (
              <p className="attach_desc">{attach_desc}</p>
            ) : null}
            <AttachModifier
              label="Reload Time: "
              value={original_reload_time.toFixed(2)}
              isPositiveGood={false}
              isAddSign={false}
              isLabelShowColor={true}
              postWords="sec"
            />
            <AttachModifier
              label="Vertical Recoil"
              value={attach_vertical_recoil}
              isPositiveGood={false}
              postWords="%"
            />
            <AttachModifier
              label="Horizontal Recoil"
              value={attach_horizontal_recoil}
              isPositiveGood={false}
              postWords="%"
            />
            <AttachModifier
              label="Recoil Snappiness"
              value={attach_recoil_snappiness}
              isPositiveGood={false}
              postWords="%"
            />
            <AttachModifier
              label="Recoil Return Speed"
              value={attach_recoil_return_speed}
              isPositiveGood={true}
              postWords="%"
            />
            <AttachModifier
              label="Terminal Shots"
              value={attach_terminal_shots}
              isPositiveGood={false}
            />
            <AttachModifier
              label="Reload Time"
              value={attach_reload_time}
              isPositiveGood={false}
              postWords="%"
            />
            <AttachModifier
              label="Trigger Threshold"
              value={attach_trigger_threshold}
              isPositiveGood={false}
              postWords="%"
            />
            <AttachModifier
              label="Damage Range"
              value={attach_range}
              isPositiveGood={true}
              postWords="%"
            />
            <AttachModifier
              label="RPM"
              value={attach_rpm}
              isPositiveGood={true}
            />
            <AttachModifier
              label="Bullet Velocity"
              value={attach_bullet_velocity}
              isPositiveGood={true}
              postWords="%"
            />
            <AttachModifier
              label="Pellet Spread"
              value={attach_pellet_spread}
              isPositiveGood={false}
              postWords="%"
            />
          </div>
        ) : null}
      </div>
    );
  };
  let attach_selectors = [
    attach_selector_box(1),
    attach_selector_box(2),
    attach_selector_box(3),
    attach_selector_box(4),
    attach_selector_box(5),
    attach_selector_box(6),
  ];

  // in case there's a weapon has < 5 attachment slots.
  attach_selectors = attach_selectors.slice(0, maxAttach);
  // only show up to picked + 1 attachment slots, default aren't counted
  // as picked
  let current_picked = 0;
  if (attach1 && attach1 != get_default_id(attachType1)) {
    current_picked += 1;
  }
  if (attach2 && attach2 != get_default_id(attachType2)) {
    current_picked += 1;
  }
  if (attach3 && attach3 != get_default_id(attachType3)) {
    current_picked += 1;
  }
  if (attach4 && attach4 != get_default_id(attachType4)) {
    current_picked += 1;
  }
  if (attach5 && attach5 != get_default_id(attachType5)) {
    current_picked += 1;
  }
  if (attach6 && attach6 != get_default_id(attachType6)) {
    current_picked += 1;
  }
  attach_selectors = attach_selectors.slice(0, current_picked + 1);

  // ===================== Gather Loadout Selected =====================
  let attachments_selected = {};

  // attachment display
  let attachments_to_display = {};
  for (const a_type of supported_attached_type) {
    attachments_to_display[a_type] = "Default";
  }
  const get_attachment_name = (a_id) =>
    all_attachment.find((a) => a._id.$oid == a_id).name;

  if (attach1) {
    attachments_selected[attachType1] = { $oid: attach1 };
    attachments_to_display[attachType1] = get_attachment_name(attach1);
  }
  if (attach2) {
    attachments_selected[attachType2] = { $oid: attach2 };
    attachments_to_display[attachType2] = get_attachment_name(attach2);
  }
  if (attach3) {
    attachments_selected[attachType3] = { $oid: attach3 };
    attachments_to_display[attachType3] = get_attachment_name(attach3);
  }
  if (attach4) {
    attachments_selected[attachType4] = { $oid: attach4 };
    attachments_to_display[attachType4] = get_attachment_name(attach4);
  }
  if (attach5) {
    attachments_selected[attachType5] = { $oid: attach5 };
    attachments_to_display[attachType5] = get_attachment_name(attach5);
  }
  if (attach6) {
    attachments_selected[attachType6] = { $oid: attach6 };
    attachments_to_display[attachType6] = get_attachment_name(attach6);
  }
  let new_loadout = {
    name: "loadout1",
    weapon: {
      $oid: weapon,
    },
    attachments: attachments_selected,
    skin: {
      $oid: skin,
    },
  };
  const reload_preview = async (_event) => {
    setShowPreview(false);
    await delay(150);
    setShowPreview(true);
  };
  const handle_clear = async (event) => {
    event.preventDefault();

    // Clear will break preview, reload at this time
    reload_preview(null);

    setAttach1(null);
    setAttach2(null);
    setAttach3(null);
    setAttach4(null);
    setAttach5(null);
    setAttach6(null);

    setAttachType1(null);
    setAttachType2(null);
    setAttachType3(null);
    setAttachType4(null);
    setAttachType5(null);
    setAttachType6(null);

    setDisabledAttachType([]);
  };
  const handle_save = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    console.log("saving loadout", JSON.stringify(new_loadout));

    fetch(
      "/api/update/player_info/loadout/save?loadout_selection=Loadout1&id=" +
        playerInfo.player_id.$oid,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(new_loadout),
      }
    )
      .then(() => {
        // redirects to player info view
        window.location.replace(window.location.origin + "?forward=loadout");
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const action_button = (
    <div className="frame">
      {cost > 0 ? (
        <h2 className="cost">
          <img className="coins" src={coinsLogo} />
          {cost}
          <span>{" /"}</span>
          {playerInfo.coins}
        </h2>
      ) : null}
      <div className="hori">
        <a className="btn secondary" onClick={handle_clear}>
          <span>CLEAR</span>
        </a>
        {playerInfo.coins < cost ? null : (
          <a className="btn primary" onClick={handle_save}>
            <span>{cost > 0 ? "PURCHASE" : "SAVE"}</span>
          </a>
        )}
      </div>
    </div>
  );

  const handle_back = async (_event) => {
    await delay(300);
    window.location.replace(window.location.origin + "?forward=loadout");
  };
  return (
    <div className="box center">
      <div className="back arrow" onClick={handle_back}>
        <div className="arrow-top"></div>
        <div className="arrow-bottom"></div>
      </div>
      {showPreview ? null : (
        <div className="frame">
          <h1>Preview Loadout</h1>
          <label>
            <input
              type="checkbox"
              checked={showPreview}
              onChange={() => setShowPreview(!showPreview)}
            />
            <span className="hide_email">Enable (may not run on mobile)</span>
          </label>
        </div>
      )}
      <div className="frame">
        <h1>Weapon</h1>
        <div className="weapon_skin_selector">
          {weapon_selector}
          {skin_selector}
        </div>
      </div>
      <div className="frame">
        <h1>Attachment</h1>
        <div className="attach_wrapper">{attach_selectors}</div>
      </div>
      {action_button}
      {showPreview ? (
        <>
          <div className="unity_wrapper unity_placeholder"></div>
          <LoadoutPreview
            weaponSelected={weapon_selected.name}
            skinSelected={
              weapon_selected.skins.find((e) => e._id.$oid === skin).name
            }
            attachmentSelected={attachments_to_display}
          />
        </>
      ) : null}
    </div>
  );
}
