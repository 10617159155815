/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import "./css/Room.css";

import { React, useState, useEffect } from "react";
import { delay } from "./Utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as starSolid } from "@fortawesome/free-solid-svg-icons";
import { faStar as star } from "@fortawesome/free-regular-svg-icons";

export function Room({ userInfo, logout, fav_room, setIsLoading }) {
  const [roomType, setRoomType] = useState(null);
  const [teamRed, setTeamRed] = useState(null);
  const [teamBlue, setTeamBlue] = useState(null);
  const [waitlist, setWaitList] = useState(null);
  const [pref, setPref] = useState("AutoFill");
  const [joined, setJoined] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const [roomError, setRoomError] = useState("");
  const [isTipShown, setIsTipShown] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);
  let roomOwnerId = urlParams.get("room_owner_id") ?? userInfo._id.$oid;

  const updateRoom = (showLoading) => {
    if (showLoading) {
      setIsLoading(true);
    }
    fetch("/api/room/get?room_owner_id=" + roomOwnerId)
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          // default error
          setRoomError("Room is not available at this time.");
          if (data.error == "NoValidPass") {
            setRoomError("A valid game pass is needed.");
          }
        } else {
          setRoomType(data.room_type);
          setRoomError("");
          setTeamRed(data.room.team_red);
          setTeamBlue(data.room.team_blue);
          setWaitList(data.room.waitlist);
          setJoined(
            data.room.team_red
              .map((v) => v[0].id.$oid)
              .includes(userInfo.player_id.$oid) ||
              data.room.team_blue
                .map((v) => v[0].id.$oid)
                .includes(userInfo.player_id.$oid) ||
              data.room.waitlist
                .map((v) => v[0].id.$oid)
                .includes(userInfo.player_id.$oid)
          );
        }
      })
      .catch((e) => {
        console.log(e);
        setRoomError(true);
      })
      .finally(() => {
        if (showLoading) {
          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    // Set fav room initially
    if (fav_room == roomOwnerId) {
      setIsFavorite(true);
    }

    updateRoom(true);

    const interval = setInterval(() => {
      updateRoom(false);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  // Room url must have room_owner_id
  const queryParams = new URLSearchParams(window.location.search);
  if (!roomError && !queryParams.get("room_owner_id")) {
    setRoomError(true);
  }

  const error_frame = (
    <div className="frame error">
      <svg
        aria-hidden="true"
        className="w-6 h-6 text-red-500 dark:text-red-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
      <p>{roomError}</p>
    </div>
  );

  const get_team_display = (team) => {
    var team_class;
    if (team == "Blue") {
      team_class = "team_blue";
    } else if (team == "Red") {
      team_class = "team_red";
    } else {
      team_class = "team_autofill";
    }
    return <div className={team_class}></div>;
  };
  const show_player_list = (players) => (
    <table className="player_list">
      <tbody>
        {players
          ? players.map((p) => (
              <tr key={p[0]}>
                <th className="player">
                  <pre>{p[0].display_card}</pre>
                  {get_team_display(p[1])}
                </th>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );

  const handleJoin = (event) => {
    event.preventDefault();
    fetch("/api/room/join?room_owner_id=" + roomOwnerId)
      .then((response) => response.json())
      .then((data) => {
        setJoined(true);
        setTeamRed(data.room.team_red);
        setTeamBlue(data.room.team_blue);
        setWaitList(data.room.waitlist);
      })
      .catch(() => {
        setRoomError(true);
      });
  };
  const join = (
    <a className="btn primary" onClick={handleJoin}>
      <span>JOIN</span>
    </a>
  );

  const handleLeave = (event) => {
    event.preventDefault();
    fetch("/api/room/leave?room_owner_id=" + roomOwnerId)
      .then((response) => response.json())
      .then((data) => {
        setJoined(false);
        setTeamRed(data.room.team_red);
        setTeamBlue(data.room.team_blue);
        setWaitList(data.room.waitlist);
      })
      .catch(() => {
        setRoomError(true);
      });
  };
  const leave = (
    <a className="btn primary" onClick={handleLeave}>
      <span>LEAVE</span>
    </a>
  );

  let room_type;
  switch (roomType) {
    case "OneVsOne":
      room_type = "Duel";
      break;
    case "TwoVsTwo":
      room_type = "Team Match";
      break;
    case "Zombie":
      room_type = "Human Vs Zombie";
      break;
    default:
      console.log("unknown room type", roomType);
      break;
  }
  const handle_fav = (e) => {
    e.preventDefault();
    setIsFavorite(!isFavorite);

    fetch(
      "/api/update/player_info/fav_room?id=" +
        userInfo.player_id.$oid +
        (isFavorite ? "" : "&room_id=" + roomOwnerId)
    ).catch((e) => {
      console.log(e);
    });
  };
  const room_info = (
    <>
      <div className="frame">
        <h1>
          {room_type}
          <a
            className={isFavorite ? "favorite-button--on" : "favorite-button"}
            href="#"
            onClick={handle_fav}
          >
            <FontAwesomeIcon
              className="favorite-button-icon"
              icon={isFavorite ? starSolid : star}
            />
            <div
              hidden={fav_room || isTipShown || isFavorite}
              className="tooltip-lens"
              onClick={() => {
                setIsTipShown(true);
              }}
            >
              <div className="ballon">
                <div className="wrapper-content">
                  <p>Bookmark the Queue</p>
                </div>
              </div>
            </div>
          </a>
        </h1>
        <h2>Red Team</h2>
        {show_player_list(teamRed)}
        {teamBlue && teamBlue.length > 0 ? (
          <>
            <span className="vs">VS</span>
            <h2>Blue Team</h2>
            {show_player_list(teamBlue)}
          </>
        ) : null}
      </div>
      <div className="frame">
        {waitlist && waitlist.length > 0 ? (
          <>
            <h2>Wait List ({waitlist.length})</h2>
            {show_player_list(waitlist)}
          </>
        ) : null}
        <div className="action">{joined ? leave : join}</div>
      </div>
    </>
  );

  // team pref
  const handle_save_selection = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    fetch("/api/room/set_pref?room_owner_id=" + roomOwnerId + "&pref=" + pref)
      .then((response) => response.json())
      .then((data) => {
        setJoined(false);
        setTeamRed(data.room.team_red);
        setTeamBlue(data.room.team_blue);
        setWaitList(data.room.waitlist);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const team_pref_select = (
    <div className="frame">
      <h1>Team Preference</h1>
      <div className="hori">
        <div className="weapon_wrapper">
          <div className="attach_box">
            <div className="select">
              <select value={pref} onChange={(e) => setPref(e.target.value)}>
                <option default value="AutoFill">
                  Auto Fill
                </option>
                <option value="Blue">Blue</option>
                <option value="Red">Red</option>
              </select>
            </div>
          </div>
        </div>
        <a
          className="btn secondary edit_button"
          onClick={handle_save_selection}
        >
          <span>Save</span>
        </a>
      </div>
    </div>
  );

  const handle_back = async (_event) => {
    await delay(300);
    window.location.replace(
      window.location.origin + "?forward=join_room&room_owner_id=" + roomOwnerId
    );
  };

  return (
    <div className="box center">
      <div className="back arrow" onClick={handle_back}>
        <div className="arrow-top"></div>
        <div className="arrow-bottom"></div>
      </div>
      {roomError ? (
        error_frame
      ) : (
        <>
          {room_info}
          {team_pref_select}
        </>
      )}
    </div>
  );
}
