/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import "./css/Loading.css";

import { React } from "react";

export function Loading({ isLoading }) {
  return (
    <div hidden={!isLoading}>
      <div className="loading">
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
        <div className="wave"></div>
      </div>
    </div>
  );
}
