/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */

import "./css/VoucherRedeem.css";

import { React, useState } from "react";
import { delay } from "./Utils";

function checkVoucher(code) {
  return code && /^[0-9]{8}$/.test(code);
}

export function VoucherRedeem({ playerInfo, setIsLoading }) {
  const [voucherEntered, setVoucherEntered] = useState("");
  const [error, setError] = useState(false);

  // Sanity check
  if (!playerInfo) {
    return null;
  }
  const handleVoucherChange = (event) => {
    event.preventDefault();
    if (/^[0-9]{0,8}$/.test(event.target.value)) {
      setVoucherEntered(event.target.value);
      setError(false);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);
    fetch(
      "/api/voucher/use?player_id=" +
        playerInfo.player_id.$oid +
        "&v_id=" +
        voucherEntered.replace("-", ""),
      {
        method: "POST",
      }
    )
      .then((resp) => resp.json())
      .then((resp) => {
        if (resp.use_succeed) {
          window.location.replace(window.location.origin + "?forward=voucher");
        } else {
          console.log("error");
          setError(true);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handle_back = async (event) => {
    event.preventDefault();
    await delay(300);
    window.location.replace(window.location.origin + "?forward=voucher");
  };
  return (
    <div className="box center">
      <div className="back arrow" onClick={handle_back}>
        <div className="arrow-top"></div>
        <div className="arrow-bottom"></div>
      </div>

      <div className="frame">
        <h1>Redeem Voucher</h1>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__group field">
            <input
              type="input"
              inputMode="numeric"
              className="form__field voucher_input"
              placeholder="Voucher Code"
              name="code"
              id="code"
              value={voucherEntered}
              required
              autoFocus
              onChange={handleVoucherChange}
            />
            <label htmlFor="name" className="form__label">
              Voucher Code
            </label>
          </div>
          <div hidden={!error}>
            <p className="error_message">Invalid voucher code!</p>
          </div>

          <div
            hidden={!checkVoucher(voucherEntered) || error || !voucherEntered}
          >
            <a className="btn primary" onClick={handleSubmit}>
              <span>REDEEM</span>
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}
