/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */

import "./css/VoucherCreate.css";

import { React, useState, useEffect } from "react";
import { delay } from "./Utils";

export function VoucherCreate({ setIsLoading }) {
  const [voucherAmount, setVoucherAmount] = useState(5);
  const [voucherAvailable, setVoucherAvailable] = useState([]);
  const [error, setError] = useState(false);

  const updateVoucher = () => {
    fetch("/gm/api/voucher/fetch")
      .then((response) => response.json())
      .then((data) => {
        const vouchers = data.vouchers.map((v) => [
          v.coin_amount,
          v.voucher_code,
        ]);
        setVoucherAvailable(vouchers);
      });
  };
  useEffect(() => {
    updateVoucher();

    const interval = setInterval(updateVoucher, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleVoucherAmountChange = (event) => {
    event.preventDefault();
    setVoucherAmount(event.target.value);
    setError(false);
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);
    fetch("/gm/api/voucher/create?c_amt=" + voucherAmount, {
      method: "POST",
    })
      .then((resp) => resp.json())
      .then((resp) => {
        voucherAvailable.unshift([voucherAmount, resp.v_code]);
        setVoucherAvailable(voucherAvailable);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handle_back = async (event) => {
    event.preventDefault();
    await delay(300);
    window.location.replace(window.location.origin + "?forward=voucher");
  };
  console.log(voucherAvailable);
  return (
    <div className="box center">
      <div className="back arrow" onClick={handle_back}>
        <div className="arrow-top"></div>
        <div className="arrow-bottom"></div>
      </div>
      <div className="frame">
        <h1>Create Voucher</h1>
        <div className="voucher_wrapper">
          <div className="attach_box">
            <div className="attach_type">Coin Amount</div>
            <div className="select">
              <select
                value={voucherAmount}
                onChange={handleVoucherAmountChange}
              >
                <option default value={5}>
                  5
                </option>
                <option value={10}>10</option>
                <option value={15}>15</option>
                <option value={20}>20</option>
                <option value={25}>25</option>
                <option value={50}>50</option>
                <option value={100}>100</option>
              </select>
            </div>
          </div>
        </div>
        <div hidden={!error}>
          <p className="error_message">Failed to create voucher!</p>
        </div>
        <a className="btn primary" onClick={handleSubmit}>
          <span>Create</span>
        </a>
      </div>
      {voucherAvailable.length > 0 ? (
        <div className="frame">
          <h1>Vouchers Created</h1>

          <table className="voucher_display">
            <tbody>
              <tr>
                <th>Coin Amount</th>
                <th>Voucher Code</th>
              </tr>
              {voucherAvailable.map((e) => (
                <tr key={e[1]}>
                  <td>{e[0]}</td>
                  <td>{e[1]}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : null}
    </div>
  );
}
