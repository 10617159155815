import * as jose from "jose";

export function getTokenPayload(token) {
  return jose.decodeJwt(token);
}

// export async function verifyJwt(token) {
//   let secret = Uint8Array.from(SECRET, (c) => c.charCodeAt(0));
//   let claim = await jose.jwtVerify(token, secret, {
//     maxTokenAge: MAX_TOKEN_AGE,
//   });
//   return claim;
// }

// export async function encodeJwt(payload) {
//   let secret = Uint8Array.from(SECRET, (c) => c.charCodeAt(0));
//   let token = await new jose.SignJWT(payload)
//     .setProtectedHeader({ alg: "HS256", typ: "JWT" })
//     .setIssuedAt()
//     .setExpirationTime(MAX_TOKEN_AGE)
//     .sign(secret);
//   return token;
// }

function epoch_sec() {
  return Math.floor(Date.now() / 1000);
}

export function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function loadTokenFromCookie() {
  return document.cookie
    .split(";")
    .filter((item) => item.trim().startsWith("token="))
    .map((token) => token.split("=")[1])
    .at(0);
}

export function setTokenToSession(token) {
  window.localStorage.setItem("token", token);
}

function fetchTokenFromSession() {
  return window.localStorage.getItem("token");
}

export function fetchActiveTokenFromSession() {
  if (!hasActiveSession) {
    return null;
  }
  return fetchTokenFromSession();
}

export function clearPrevSession() {
  document.cookie = "token=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  window.sessionStorage.clear();
  window.localStorage.clear();
}

function hasActiveSession() {
  let prev_token = fetchTokenFromSession();
  if (prev_token) {
    let prev_claim = getTokenPayload(prev_token);
    let res = prev_claim.exp > epoch_sec();
    return res;
  }
  return false;
}
