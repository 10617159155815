/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */

import { React } from "react";
import { PlayerRegistration } from "./PlayerRegistration";
import { Player } from "./Player";
import { Loadout } from "./Loadout";
import { Room } from "./Room";
import { GameMasterView } from "./GameMasterView";
import { VoucherRedeem } from "./VoucherRedeem";
import { VoucherCreate } from "./VoucherCreate";
import { PassUse } from "./PassUse";
import { PassCreate } from "./PassCreate";
import { LoginAs } from "./LoginAs";

export function Redirect({
  userInfo,
  playerInfo,

  setUserInfo,
  setPlayerInfo,
  setQlink,
  setIsLoading,
  setIsNewPlayer,
  logout,
}) {
  // Sanite check
  // Return nothing if no active session or userInfo not loaded yet
  if (!userInfo) {
    return null;
  }

  const is_gm = userInfo.permission_level >= 100;
  const url = window.location.href;

  // logout
  if (url.includes("/logout")) {
    logout();
  }

  // load player registration if no player
  if (!userInfo.player_id) {
    return (
      <PlayerRegistration
        logout={logout}
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        setIsLoading={setIsLoading}
        setIsNewPlayer={setIsNewPlayer}
      />
    );
  }

  // load player info
  if (!playerInfo) {
    setIsLoading(true);
    fetch("/api/get/player_info?id=" + userInfo.player_id.$oid)
      .then((response) => response.json())
      .then((data) => {
        setPlayerInfo(data.PlayerInfo);
        setQlink({
          name: data.PlayerInfo.player_name,
          coins: data.PlayerInfo.coins,
        });
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
    return;
  }

  // url handling
  if (url.includes("/join_room")) {
    console.log(playerInfo);
    return (
      <Room
        userInfo={userInfo}
        logout={logout}
        fav_room={playerInfo.fav_room_id?.$oid}
        setIsLoading={setIsLoading}
      />
    );
  }
  if (url.includes("/loadout")) {
    return (
      <Loadout
        logout={logout}
        playerInfo={playerInfo}
        setIsLoading={setIsLoading}
      />
    );
  }
  if (url.includes("/voucher")) {
    if (is_gm) {
      return <VoucherCreate setIsLoading={setIsLoading} />;
    } else {
      return (
        <VoucherRedeem playerInfo={playerInfo} setIsLoading={setIsLoading} />
      );
    }
  }
  if (url.includes("/pass")) {
    if (is_gm) {
      return <PassCreate setIsLoading={setIsLoading} />;
    } else {
      return <PassUse userInfo={userInfo} setIsLoading={setIsLoading} />;
    }
  }
  if (is_gm && url.includes("/login_as")) {
    return <LoginAs setIsLoading={setIsLoading} />;
  }

  // Default View
  if (is_gm) {
    return <GameMasterView playerInfo={playerInfo} logout={logout} />;
  } else {
    return (
      <Player
        userInfo={userInfo}
        logout={logout}
        playerInfo={playerInfo}
        setPlayerInfo={setPlayerInfo}
        setIsLoading={setIsLoading}
      />
    );
  }
}
