/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/display-name */

import React, { useContext, useState, useEffect, useRef } from "react";
import UnityContext from "./UnityContext";
import { Unity, useUnityContext } from "react-unity-webgl";
import { isMobile } from "react-device-detect";
import { delay } from "./Utils";

export function LoadoutPreview({
  weaponSelected,
  skinSelected,
  attachmentSelected,
}) {
  const unityContext = useContext(UnityContext);
  const [isWebGLReady, setIsWebGLReady] = useState(false);
  const {
    unityProvider,
    isLoaded,
    sendMessage,
    addEventListener,
    removeEventListener,
  } = useUnityContext(unityContext.context);
  // ensure that we only call webgl at most once per second even during rerendering.
  const lastUpdated = useRef(0);

  const update_preview = async (
    weaponSelected,
    skinSelected,
    attachmentSelected
  ) => {
    console.log("weapon to display:", weaponSelected);
    console.log("skin to display:", skinSelected);
    console.log("attach to display:", attachmentSelected);

    // 1. Display weapon change (to laod the new weapon if needed)
    sendMessage("ReactBridge", "ShowWeapon", weaponSelected);

    // 2. Then load attachments
    for (const [a_type, a_name] of Object.entries(attachmentSelected)) {
      sendMessage("ReactBridge", "ChangeAttachment", a_type + ":" + a_name);
    }

    // 3. Render skin on attachment in the end
    sendMessage("ReactBridge", "ChangeSkin", skinSelected);
  };
  const tryUpdate = () => {
    const now = Date.now();
    if (now - lastUpdated.current >= 1500) {
      if (isLoaded && isWebGLReady) {
        update_preview(weaponSelected, skinSelected, attachmentSelected);
        lastUpdated.current = now;
      }
    }
  };

  useEffect(() => {
    addEventListener("SetIsWebGLReady", setIsWebGLReady);

    const updatePreviewTimer = setInterval(() => {
      tryUpdate();
    }, 500);

    // Clean up function to clear the interval when the component unmounts
    return () => {
      removeEventListener("SetIsWebGLReady", setIsWebGLReady);
      clearInterval(updatePreviewTimer);
    };
  });

  return (
    <div className="unity_wrapper">
      <Unity
        className="webgl"
        unityProvider={unityProvider}
        style={{
          aspectRatio: "2/1",
          width: "100%",
          maxWidth: "512px",
          maxHeight: "300px",
          marginLeft: "auto",
          marginRight: "auto",
          display: "block",
        }}
      />
    </div>
  );
}

// function areEqual(old_p, new_p) {
//   if (old_p.weaponSelected != new_p.weaponSelected) {
//     return false;
//   }
//   if (old_p.skinSelected != new_p.skinSelected) {
//     return false;
//   }

//   if (old_p.attachmentSelected && new_p.attachmentSelected) {
//     let old_attach = Object.values(old_p.attachmentSelected);
//     let new_attach = Object.values(new_p.attachmentSelected);
//     if (old_attach.length != new_attach.length) {
//       return false;
//     }
//     return old_attach.every((attach, i) => attach == new_attach[i]);
//   }

//   if (!old_p.attachmentSelected && !new_p.attachmentSelected) {
//     return true;
//   }
//   return false;
// }

// export { LoadoutPreview };
