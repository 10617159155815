/* eslint-disable no-unused-vars */

import React, { useContext } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import { App } from "./App";
import UnityContext from "./UnityContext";

export function AppLoader() {
  // WebGL loading
  const unityContextLB = {
    loaderUrl:
      "https://lb.slashvr.io/loadout_webgl/Build/loadout_webgl.loader.js",
    dataUrl: "https://lb.slashvr.io/loadout_webgl/Build/loadout_webgl.data",
    frameworkUrl:
      "https://lb.slashvr.io/loadout_webgl/Build/loadout_webgl.framework.js",
    codeUrl: "https://lb.slashvr.io/loadout_webgl/Build/loadout_webgl.wasm",
  };
  const unityContextGSDirect = {
    loaderUrl:
      "https://storage.googleapis.com/webgl_hosting/loadout_webgl_br/Build/loadout_webgl_br.loader.js",
    dataUrl:
      "https://storage.googleapis.com/webgl_hosting/loadout_webgl_br/Build/loadout_webgl_br.data.br",
    frameworkUrl:
      "https://storage.googleapis.com/webgl_hosting/loadout_webgl_br/Build/loadout_webgl_br.framework.js.br",
    codeUrl:
      "https://storage.googleapis.com/webgl_hosting/loadout_webgl_br/Build/loadout_webgl_br.wasm.br",
  };
  const unityContext = {
    loaderUrl: "https://slashvr.io/webgl/loadout_webgl_br.loader.js",
    dataUrl: "https://slashvr.io/webgl/loadout_webgl_br.data.br",
    frameworkUrl: "https://slashvr.io/webgl/loadout_webgl_br.framework.js.br",
    codeUrl: "https://slashvr.io/webgl/loadout_webgl_br.wasm.br",
  };
  const unityContextDev = {
    loaderUrl: "https://slashvr.io/webgl/loadout_webgl.loader.js",
    dataUrl: "https://slashvr.io/webgl/loadout_webgl.data",
    frameworkUrl: "https://slashvr.io/webgl/loadout_webgl.framework.js",
    codeUrl: "https://slashvr.io/webgl/loadout_webgl.wasm",
  };

  return (
    <>
      <UnityContext.Provider
        value={{
          context: unityContextLB,
        }}
      >
        <App />
      </UnityContext.Provider>
    </>
  );
}
