/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import "./css/PlayerRegistration.css";

import { React, useState } from "react";
import { delay } from "./Utils";

function checkUsername(username) {
  return {
    minLength: username,
    maxLength: username && username.length <= 15,
    hasValidChars: username && /^(\w|[\u4e00-\u9fa5])+$/.test(username),
  };
}

export function PlayerRegistration({
  logout,
  userInfo,
  setUserInfo,
  setIsLoading,
  setIsNewPlayer,
}) {
  // Sanity check
  if (!userInfo) {
    return null;
  }

  const [usernameEntered, setUsernameEntered] = useState(
    userInfo.email ? userInfo.email.split("@")[0] : ""
  );
  const [usernameUsed, setUsernameUsed] = useState(false);

  const handleUsernameChange = (event) => {
    event.preventDefault();
    setUsernameUsed(false);
    setUsernameEntered(event.target.value);
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    fetch(
      "/api/update/user_info?id=" +
        userInfo._id.$oid +
        "&add_player=" +
        usernameEntered,
      {
        method: "POST",
      }
    )
      .then((resp) => resp.json())
      .then(async (resp) => {
        if (resp.error == "PlayerAlreadyExist") {
          setUsernameUsed(true);
          return;
        }

        // Keeps fetching user info
        setIsLoading(true);
        delay(500).then(() => {
          fetch("/api/get/user_info?id=" + userInfo._id.$oid)
            .then((resp) => resp.json())
            .then((data) => {
              let new_userInfo = data.UserInfo;
              if (new_userInfo.player_id) {
                setIsLoading(false);
                setUserInfo(new_userInfo);
                setIsNewPlayer(true);
              } else {
                // Registration failed, logout
                setIsLoading(false);
                logout();
              }
            });
        });
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const usernameChecks = checkUsername(usernameEntered);
  const isValidInput =
    usernameEntered &&
    !usernameUsed &&
    usernameChecks.minLength &&
    usernameChecks.maxLength &&
    usernameChecks.hasValidChars;
  return (
    <div className="box center">
      <div className="frame">
        <h1>Create Your Player</h1>
        <form className="form" onSubmit={handleSubmit}>
          <div className="form__group field">
            <input
              type="input"
              className="form__field"
              placeholder="Name"
              name="name"
              id="name"
              value={usernameEntered}
              required
              autoFocus
              onChange={handleUsernameChange}
            />
            <label htmlFor="name" className="form__label">
              Player Name
            </label>
          </div>
          <div hidden={!usernameEntered || isValidInput}>
            <ul>
              <li hidden={usernameChecks.maxLength}>
                No more than 15 characters
              </li>
              <li hidden={usernameChecks.hasValidChars}>No symbols allowed</li>
              <li hidden={!usernameUsed}>This name has been taken</li>
            </ul>
          </div>
          <div
            hidden={usernameEntered && !isValidInput}
            style={{ visibility: isValidInput ? "visible" : "hidden" }}
          >
            <a className="btn primary" onClick={handleSubmit}>
              <span>CONFIRM</span>
            </a>
          </div>
        </form>
      </div>
    </div>
  );
}
