/* eslint-disable no-unused-vars */
import "./css/App.css";
import "./css/Box.css";
import join_discord_button from "./static/discord-button.png";
import slashvr_logo from "./static/slashvr-logo-white.png";
import React, { useState, useEffect } from "react";
import { SignInScreen } from "./SignInScreen";
import { Redirect } from "./Redirect";
import compatApp from "firebase/compat/app";
import { Banner } from "./Banner";
import {
  getIdToken,
  onAuthStateChanged,
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";
import { Loading } from "./Loading";
import {
  delay,
  getTokenPayload,
  setTokenToSession,
  fetchActiveTokenFromSession,
  clearPrevSession,
  loadTokenFromCookie,
} from "./Utils";

// Firebase
const FIREBASE_CONFIG = {
  apiKey: "AIzaSyCS8TEkEpJy9i_7kQCnlxOvt5fu0lPoR4A",
  authDomain: "metaarms.firebaseapp.com",
  projectId: "metaarms",
  storageBucket: "metaarms.appspot.com",
  messagingSenderId: "46817808101",
  appId: "1:46817808101:web:b3cef7d5b3f4bc083d4e0c",
};
export const FCM_APP = compatApp.initializeApp(FIREBASE_CONFIG);

export function App() {
  const [userInfo, setUserInfo] = useState(null);
  const [playerInfo, setPlayerInfo] = useState(null);
  const [error, setError] = useState(false);
  const [qlink, setQlink] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isNewPlayer, setIsNewPlayer] = useState(false);

  useEffect(() => {
    let prev_token = fetchActiveTokenFromSession();
    if (prev_token) {
      // fetcher user_info with previous token
      console.log("prev_token", prev_token);
      fetch(
        "/api/get/user_info?id=" +
          getTokenPayload(prev_token).session.user_id.$oid +
          "&token=" +
          prev_token
      )
        .then((response) => {
          if (response.status == 200) {
            return response.json();
          } else {
            // clear prev stored session and force a login via google
            // note that unlike logout(), we didn't sign out google here so any valid google
            // signin sessions will continue to work by performing a login request for the
            // user automatically.
            clearPrevSession();
            window.location.reload();
          }
        })
        .then((data) => {
          setUserInfo(data.UserInfo);
        });
    } else {
      // Register Google Auth listener only if there's no prev login
      const unregisterAuthObserver = onAuthStateChanged(
        getAuth(FCM_APP),
        (user) => {
          if (user) {
            const login = async (token) => {
              setIsLoading(true);
              fetch("/api/login/" + token)
                .then((response) => {
                  if (response.status == 200) {
                    return response.json();
                  }
                  setError(true);
                })
                .then(async (data) => {
                  console.log("Server login response: " + JSON.stringify(data));
                  setTokenToSession(data.token);

                  let session = getTokenPayload(data.token).session;
                  console.log(
                    "Session: " +
                      JSON.stringify(getTokenPayload(data.token).session)
                  );

                  fetch("/api/get/user_info?id=" + session.user_id.$oid)
                    .then((response) => response.json())
                    .then((data) => {
                      setUserInfo(data.UserInfo);
                    });
                })
                .catch(() => {
                  console.log("login fail");
                  setError(true);
                })
                .finally(() => {
                  setIsLoading(false);
                });
            };
            getIdToken(user).then(async (token) => {
              login(token);
            });
          }
        }
      );
      return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
    }
  }, []);

  const logout = async () => {
    console.log("logout");
    await getAuth(FCM_APP).signOut();
    // logout
    fetch("/api/logout").finally(() => {
      console.log("logged out");
      clearPrevSession();
      setUserInfo(null);
      window.location.replace(window.location.origin);
    });
  };

  let body = null;
  if (!userInfo && !fetchActiveTokenFromSession()) {
    if (window.location.href != window.location.origin + "/") {
      window.location.replace(window.location.origin);
    }

    body = <SignInScreen error={error} />;
  } else {
    body = (
      <Redirect
        userInfo={userInfo}
        setUserInfo={setUserInfo}
        playerInfo={playerInfo}
        setPlayerInfo={setPlayerInfo}
        setQlink={setQlink}
        setIsLoading={setIsLoading}
        setIsNewPlayer={setIsNewPlayer}
        logout={logout}
      />
    );
  }

  return (
    <div className="App">
      <Banner
        logout={logout}
        qlink={qlink}
        userInfo={userInfo}
        isNewPlayer={isNewPlayer}
      />
      <Loading isLoading={isLoading} />
      <div className={isLoading ? "hidden Body" : "Body"}>{body}</div>
      <footer className="footer">
        <div className="footer-col">
          <h4>SlashVR Inc.</h4>
          <ul>
            <li>
              <img className="slashvr_logo" src={slashvr_logo} />
            </li>
            <li>
              <a href="https://discord.gg/cvdxUhtYue">
                <img className="join_discord" src={join_discord_button} />
              </a>
            </li>
          </ul>
        </div>
      </footer>
    </div>
  );
}
