/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import "./css/Player.css";
import coinsLogo from "./static/icons8-coin-64.png";

import { React, useEffect, useState } from "react";
import { delay } from "./Utils";
import { AttachmentTypes } from "./Loadout";

const DAY_PASS_STRIPE_LINK = "https://buy.stripe.com/bIY8yy20hfrXa7C288";
const ONETIME_PASS_STRIPE_LINK = "https://buy.stripe.com/cN26qqcEV3JffrW7st";
const DAY_PASS_STRIPE_LINK_TEST =
  "https://buy.stripe.com/test_eVa9ADakicIGe1G9AB";
const ONETIME_PASS_STRIPE_LINK_TEST =
  "https://buy.stripe.com/test_5kA28bfECdMK2iYbII";

export function Player({
  userInfo,
  playerInfo,
  setPlayerInfo,
  logout,
  setIsLoading,
}) {
  const [inRoom, setInRoom] = useState(false);
  const [favRoom, setFavRoom] = useState(null);
  const [passRoom, setPassRoom] = useState(null);
  const [loadoutSelected, setLoadoutSelected] = useState("PresetARLoadout");

  const update = () => {
    fetch("/api/get/player_info?id=" + userInfo.player_id.$oid)
      .then((response) => response.json())
      .then((data) => {
        setPlayerInfo(data.PlayerInfo);
        const in_room = Boolean(data.PlayerInfo.room_id);
        if (in_room != inRoom) {
          setInRoom(in_room);
        }
        setFavRoom(data.PlayerInfo.fav_room_id?.$oid);
        setPassRoom(data.PlayerInfo.pass_room_id?.$oid);
      })
      .catch((e) => {
        console.log(e);
        setPlayerInfo(null);
      });
  };

  useEffect(() => {
    update();
    if (inRoom) {
      const interval = setInterval(update, 2000);
      return () => clearInterval(interval);
    }
  }, [userInfo, inRoom]);

  // user doesn't yet have a player linked.
  if (!userInfo || !userInfo.player_id) {
    return null;
  }

  if (!playerInfo) {
    return null;
  }

  // player detail
  const player_details = (
    <table className="player_info">
      <tbody>
        <tr>
          <th>Level</th>
          <td>
            {playerInfo.level} (
            {parseInt(((playerInfo.exp % 1000) / 1000) * 100)}%)
          </td>
        </tr>
        <tr>
          <th>KDA</th>
          <td>
            {Math.round(
              ((playerInfo.kda.k + playerInfo.kda.a) /
                Math.max(playerInfo.kda.d, 1)) *
                10
            ) / 10}{" "}
            ({playerInfo.kda.k}/{playerInfo.kda.d}/{playerInfo.kda.a})
          </td>
        </tr>
        <tr>
          <th>Pass</th>
          <td>
            {(playerInfo.pass ?? "No Pass").replace(/([A-Z])/g, " $1").trim()}
          </td>
        </tr>
        <tr>
          <th>
            <img className="coins" src={coinsLogo} />
          </th>
          <td>{playerInfo.coins}</td>
        </tr>
      </tbody>
    </table>
  );

  // pass purchase
  const day_pass_link = window.location.href.includes("slashvr.io")
    ? DAY_PASS_STRIPE_LINK
    : DAY_PASS_STRIPE_LINK_TEST;
  const onetime_pass_link = window.location.href.includes("slashvr.io")
    ? ONETIME_PASS_STRIPE_LINK
    : ONETIME_PASS_STRIPE_LINK_TEST;
  const purchase_frame = playerInfo.pass ? null : (
    <div className="frame hori">
      <a
        className="btn primary purchase_button notransition"
        href={day_pass_link + "?client_reference_id=" + userInfo._id.$oid}
        rel="noreferrer"
      >
        <span>Day Pass</span>
      </a>
      <a
        className="btn secondary purchase_button notransition"
        href={onetime_pass_link + "?client_reference_id=" + userInfo._id.$oid}
        rel="noreferrer"
      >
        <span>One-time Pass</span>
      </a>
    </div>
  );

  // room
  let room_info_frame = null;
  if (inRoom || favRoom || passRoom) {
    const handle_back_to_room = (r_id) => {
      window.location.replace(
        window.location.origin + "/join_room?room_owner_id=" + r_id
      );
    };
    const room_redirect = (r_id, prompt) => (
      <a
        className="btn primary edit_button"
        onClick={() => handle_back_to_room(r_id)}
      >
        <span>{prompt}</span>
      </a>
    );
    room_info_frame = (
      <div className="frame">
        {inRoom
          ? room_redirect(playerInfo.room_id.$oid, "Back To Queue")
          : null}
        {!inRoom && passRoom
          ? room_redirect(passRoom, "Join Game Queue")
          : null}
        {!inRoom && favRoom && favRoom != passRoom
          ? room_redirect(favRoom, "Join Stared Game Queue")
          : null}
      </div>
    );
  }

  // loadout preset
  const handle_save_selection = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    fetch(
      "/api/update/player_info/loadout?loadout_selection=" +
        loadoutSelected +
        "&id=" +
        userInfo.player_id.$oid,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
      .then(() => {
        update();
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const loadout_preset = (
    <div className="frame">
      <h1>Loadout Presets</h1>
      <div className="hori">
        <div className="weapon_wrapper">
          <div className="attach_box">
            <div className="select">
              <select
                value={loadoutSelected}
                onChange={(e) => setLoadoutSelected(e.target.value)}
              >
                <option value="PresetARLoadout">AR</option>
                <option value="PresetSMGLoadout">SMG</option>
                <option value="PresetHandgunLoadout">Handgun</option>
                <option value="PresetShotgunLoadout">Shotgun</option>
                {playerInfo.level >= 3 ? (
                  <option value="Loadout1">Custom</option>
                ) : null}
              </select>
            </div>
          </div>
        </div>
        <a
          className="btn secondary edit_button"
          onClick={handle_save_selection}
        >
          <span>Save</span>
        </a>
      </div>
    </div>
  );

  // loadout customization
  const handle_edit = async (_event) => {
    window.location.replace(window.location.origin + "/" + "loadout");
  };
  const loadout_picked = playerInfo.loadout_picked;
  const loadout = (
    <table className="loadout">
      <tbody>
        <tr>
          <th>
            <h2>{loadout_picked.weapon.name}</h2>
          </th>
        </tr>
        {loadout_picked.skin.name !== "Default" ? (
          <tr>
            <th>Skin</th>
            <td>{loadout_picked.skin.name}</td>
          </tr>
        ) : null}
        {Object.entries(AttachmentTypes).map(([_, attachment_type]) => {
          let attachment = loadout_picked.attachments[attachment_type];
          if (attachment) {
            if (attachment.name !== "Default" && attachment.name !== "Empty") {
              return (
                <tr key={attachment_type}>
                  <th>{attachment_type}</th>
                  <td>{attachment.name}</td>
                </tr>
              );
            }
          }
        })}
      </tbody>
    </table>
  );

  // recent games
  const games = playerInfo.games;
  console.log("games", games);
  const recent_games = (
    <table className="gm_game_list">
      <tbody>
        <tr>
          <th>Time</th>
          <th className="score_th" colSpan="2">
            Score
          </th>
        </tr>
        {games
          ? games.map((g) => (
              <tr key={g.id.$oid}>
                <td className="match_time">
                  {new Date(g.match_time)
                    .toLocaleString("en-US")
                    .replace(",", "\n")}
                </td>
                <td>
                  <table className="score_table">
                    <tbody>
                      <tr className="score">
                        {g.score_board.map((team, idx) => (
                          <td
                            className={`${
                              team.player_stats
                                .map((s) => s[0])
                                .includes(playerInfo.player_name)
                                ? "self"
                                : "other"
                            }`}
                            key={idx}
                          >
                            {team.score} {team.won ? "(W)" : "(L)"}
                          </td>
                        ))}
                      </tr>
                      <tr className="player_stats">
                        {g.score_board.map((team, idx) => {
                          return (
                            <td key={idx}>
                              {team.player_stats.map(([name, kda]) => {
                                return (
                                  <p key={name}>
                                    {name}: {kda.k}/{kda.d}/{kda.a}
                                  </p>
                                );
                              })}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );

  const handle_forward = async (_event) => {
    await delay(300);
    const urlParams = new URLSearchParams(window.location.search);
    const forward = urlParams.get("forward");

    if (forward == "join_room") {
      window.location.replace(
        window.location.origin +
          "/" +
          "join_room" +
          "?room_owner_id=" +
          urlParams.get("room_owner_id")
      );
    }
    if (forward == "loadout") {
      window.location.replace(window.location.origin + "/loadout");
    }
    if (forward == "voucher") {
      window.location.replace(window.location.origin + "/voucher");
    }
  };

  return (
    <div className="box player">
      <div
        hidden={!new URLSearchParams(window.location.search).get("forward")}
        className="forward arrow"
        onClick={handle_forward}
      >
        <div className="arrow-top"></div>
        <div className="arrow-bottom"></div>
      </div>
      <div className="frame hori">
        <h1>{playerInfo.player_name}</h1>
        {player_details}
      </div>
      {purchase_frame}
      {room_info_frame}
      {loadout_preset}
      <div className="frame">
        <h1>Loadout</h1>
        {loadout}
        {playerInfo.level >= 3 ? (
          <a className="btn primary edit_button" onClick={handle_edit}>
            <span>Custom Loadout</span>
          </a>
        ) : (
          <p className="note">Custom loadout available at Lv.3</p>
        )}
      </div>
      <div
        style={{ visibility: games.length ? "visible" : "hidden" }}
        className="frame"
      >
        <h1>Recent Games</h1>
        <div className="scrollable">{recent_games}</div>
      </div>
    </div>
  );
}
