/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */
import "./css/GameMasterView.css";
import { React, useState, useEffect } from "react";
import { loadTokenFromCookie } from "./Utils";

export function GameMasterView({ playerInfo, logout }) {
  const [qrUrl, setQrUrl] = useState(null);
  const [showQr, setShowQr] = useState(false);
  const [teamRed, setTeamRed] = useState(null);
  const [teamBlue, setTeamBlue] = useState(null);
  const [waitlist, setWaitList] = useState(null);
  const [tokenCopied, setTokenCopied] = useState(false);
  const [games, setGames] = useState(null);
  const [showIdentifier, setShowIdentifier] = useState(false);

  const updateRoom = () => {
    fetch("/gm/api/room?get&incl_waitlist=true")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setTeamRed(data.team_red);
        setTeamBlue(data.team_blue);
        setWaitList(data.waitlist);
        setQrUrl(data.qr_url);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const updateGame = () => {
    fetch("/gm/api/match?fetch_recent=25")
      .then((response) => response.json())
      .then((data) => {
        setGames(data.games);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  useEffect(() => {
    updateRoom();
    updateGame();

    const room_interval = setInterval(updateRoom, 2000);
    const game_interval = setInterval(updateGame, 5000);
    return () => {
      clearInterval(room_interval);
      clearInterval(game_interval);
    };
  }, []);

  if (!playerInfo) {
    return null;
  }

  // QR Code
  let qr = null;
  if (qrUrl) {
    const show_vr = () => {
      setShowQr(true);
    };
    qr = (
      <div className="frame hori">
        <h1>Queue QR</h1>
        {showQr ? (
          <img className="qr_code" src={qrUrl} alt="Join room qr" />
        ) : (
          <a className="btn primary" onClick={show_vr}>
            Show QR
          </a>
        )}
      </div>
    );
  }

  // GM Token
  const copy_to_clipboard = () => {
    navigator.clipboard.writeText(loadTokenFromCookie());
    setTokenCopied(true);
    setTimeout(() => {
      setTokenCopied(false);
    }, 3000);
  };
  const token = (
    <div>
      <a className="btn primary" onClick={copy_to_clipboard}>
        {tokenCopied ? "Copied" : "Click to Copy"}
      </a>
    </div>
  );

  // Current room
  const remove_player = (player_id) => {
    fetch("/gm/api/room?remove_player=" + player_id)
      .then((response) => response.json())
      .then((data) => {
        setTeamRed(data.team_red);
        setTeamBlue(data.team_blue);
        setWaitList(data.waitlist);
      })
      .catch((e) => {
        console.log(e);
      });
  };
  const show_player = (p) => {
    return (
      <>
        <td>{p.level}</td>
        <td>
          {Math.round(((p.kda.k + p.kda.a) / Math.max(p.kda.d, 1)) * 10) / 10} (
          {p.kda.k}/{p.kda.d}/{p.kda.a})
        </td>
        <td>{p.player_name}</td>
        <td>{showIdentifier ? p.owner_identifier : " ------ "}</td>
        <td>{p.pass ?? "None"}</td>
        <td className="action">
          <button
            onClick={() => {
              remove_player(p.player_id.$oid);
            }}
          >
            X
          </button>
        </td>
      </>
    );
  };
  const playerList =
    teamRed || teamBlue || waitlist ? (
      <table className="gm_player_list">
        <tbody>
          <tr>
            <th>Status</th>
            <th>Lv</th>
            <th>KDA</th>
            <th>Name</th>
            <th>Email</th>
            <th>Pass</th>
            <th>Action</th>
          </tr>
          {teamRed
            ? teamRed.map((p) => (
                <tr key={p.player_id.$oid}>
                  <td>Red</td>
                  {show_player(p)}
                </tr>
              ))
            : null}
          {teamBlue
            ? teamBlue.map((p) => (
                <tr key={p.player_id.$oid}>
                  <td>Blue</td>
                  {show_player(p)}
                </tr>
              ))
            : null}
          {waitlist
            ? waitlist.map((p) => (
                <tr key={p.player_id.$oid}>
                  <td>Wait</td>
                  {show_player(p)}
                </tr>
              ))
            : null}
        </tbody>
      </table>
    ) : (
      <div className="error">
        <p>Queue is Empty</p>
      </div>
    );

  // Recent matches
  const recentGames = (
    <table className="gm_game_list">
      <tbody>
        <tr>
          <th>Time</th>
          <th className="score_th" colSpan="2">
            Score
          </th>
        </tr>
        {games
          ? games.map((g) => (
              <tr key={g.id.$oid}>
                <td className="match_time">
                  {new Date(g.match_time)
                    .toLocaleString("en-US")
                    .replace(",", "\n")}
                </td>
                <td>
                  <table className="score_table">
                    <tbody>
                      <tr className="score">
                        {g.score_board.map((team, idx) => (
                          <td key={idx}>
                            {team.score} {team.won ? "(W)" : "(L)"}
                          </td>
                        ))}
                      </tr>
                      <tr className="player_stats">
                        {g.score_board.map((team, idx) => {
                          return (
                            <td key={idx}>
                              {team.player_stats.map(([name, kda]) => {
                                return (
                                  <p key={name}>
                                    {name}: {kda.k}/{kda.d}/{kda.a}
                                  </p>
                                );
                              })}
                            </td>
                          );
                        })}
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );

  const handleShowIdentifier = (e) => {
    setShowIdentifier(!showIdentifier);
  };
  return (
    <div className="box">
      <div className="frame hori">
        <h1>{playerInfo.player_name}</h1>
      </div>
      {qr}
      <div className="frame hori">
        <h1>GM Token</h1>
        {token}
      </div>
      <div className="frame">
        <h1>Players in the Queue</h1>
        <label>
          <input
            type="checkbox"
            checked={!showIdentifier}
            onChange={handleShowIdentifier}
          />
          <span className="hide_email">Hide Email</span>
        </label>
        {playerList}
      </div>
      <div className="frame">
        <h1>Recent Games</h1>
        <div className="scrollable">{recentGames}</div>
      </div>
    </div>
  );
}
