/* eslint-disable react/prop-types */
import "./css/Banner.css";
import coinsLogo from "./static/icons8-coin-64.png";
import discordLogo from "./static/discord-logo-blue.png";

import { React, useState } from "react";
export function Banner({ logout, userInfo, qlink, isNewPlayer }) {
  const [hasShownLoadoutTip, setHasShownLoadoutTip] = useState(false);

  const url = window.location.href;
  let qlink_info = null;
  const is_gm = userInfo && userInfo.permission_level >= 100;
  if (userInfo && url.includes("/loadout")) {
    qlink_info = (
      <div className="qlinks">
        {qlink.name}
        <div className="coins_container">
          <img className="coins" src={coinsLogo} /> {qlink.coins}
        </div>
      </div>
    );
  } else if (userInfo) {
    qlink_info = (
      <div className="qlinks">
        <div className="logout_button" onClick={logout}>
          LOGOUT
        </div>
      </div>
    );
  }

  let menu_items = null;
  if (!is_gm) {
    menu_items = (
      <>
        <li>
          <a href={window.location.origin}>Player Home</a>
        </li>
        <li>
          <a href={window.location.origin + "/loadout"}>Loadout</a>
        </li>
        <li>
          <a href={window.location.origin + "/pass"}>Use Pass</a>
        </li>
        <li>
          <a href={window.location.origin + "/voucher"}>Redeem Voucher</a>
        </li>
      </>
    );
  } else {
    menu_items = (
      <>
        <li>
          <a href={window.location.origin}>GM Home</a>
        </li>
        <li>
          <a href={window.location.origin + "/voucher"}>New Voucher</a>
        </li>
        <li>
          <a href={window.location.origin + "/pass"}>New Pass</a>
        </li>
        <li>
          <a href={window.location.origin + "/login_as"}>Login As</a>
        </li>
      </>
    );
  }
  return (
    <div className="Banner">
      <input type="checkbox" id="menu_checkbox" />
      <label htmlFor="menu_checkbox">
        <div></div>
        <div></div>
        <div></div>
      </label>
      <ul className="slide">
        {menu_items}
        <li>
          <a href={window.location.origin + "/logout"}>Logout</a>
        </li>
        <li>
          <a href="https://discord.gg/cvdxUhtYue">
            <img className="discord_logo" src={discordLogo} />
          </a>
        </li>
      </ul>
      <div
        hidden={!isNewPlayer || hasShownLoadoutTip}
        className="tooltip-lens"
        onClick={() => setHasShownLoadoutTip(true)}
      >
        <div className="ballon">
          <div className="wrapper-content">
            <p>View more option</p>
          </div>
        </div>
      </div>

      <h1 className="title">
        <a style={{ color: "inherit", textDecoration: "inherit" }} href="/">
          MetaArms
        </a>
      </h1>
      {qlink_info}
    </div>
  );
}
