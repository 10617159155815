/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */

import { React, useState, useEffect } from "react";
import { delay } from "./Utils";

function checkPassCode(code) {
  return code && /^[0-9]{8}$/.test(code);
}

export function PassUse({ userInfo, setIsLoading }) {
  // error message display
  let [error, setError] = useState(null);

  const urlParams = new URLSearchParams(window.location.search);
  const input_pass_id = urlParams.get("p_id");
  let [passID, _] = useState(input_pass_id);

  let [passCodeEntered, setPassCodeEntered] = useState(null);

  useEffect(() => {
    // if we already know the p_id, simply use it.
    if (passID) {
      setIsLoading(true);
      fetch("/api/pass/use?p_id=" + passID)
        .then((response) => {
          if (response.status == 500) {
            setError("Only One Trial Pass Allowed");
          } else if (response.status != 200) {
            setError("Invalid Pass");
          } else {
            // Use succeed, redirect user to player home
            setError(null);
            window.location.replace(window.location.origin);
          }
        })
        .catch((e) => {
          console.log("error", e);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [userInfo]);

  // sanity check
  if (!userInfo) {
    return null;
  }

  const error_frame = (
    <div className="frame error">
      <svg
        aria-hidden="true"
        className="w-6 h-6 text-red-500 dark:text-red-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
      <p>{error}</p>
    </div>
  );

  // Pass code input
  const handlePassChange = (event) => {
    event.preventDefault();
    if (/^[0-9]{0,8}$/.test(event.target.value)) {
      setPassCodeEntered(event.target.value);
      setError(false);
    }
  };
  const handleSubmit = (event) => {
    event.preventDefault();

    setIsLoading(true);
    fetch("/api/pass/use?p_code=" + passCodeEntered.replace("-", ""), {
      method: "POST",
    })
      .then((resp) => {
        if (resp.status == 500) {
          setError("Only One Trial Pass Allowed");
        } else if (resp.status != 200) {
          setError("Invalid Pass");
        } else {
          // Use succeed, redirect user to player home
          setError(null);
          window.location.replace(window.location.origin);
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const pass_code_input = (
    <div className="frame">
      <h1>Use Pass</h1>
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__group field">
          <input
            type="input"
            inputMode="numeric"
            className="form__field voucher_input"
            placeholder="Voucher Code"
            name="code"
            id="code"
            value={passCodeEntered}
            required
            autoFocus
            onChange={handlePassChange}
          />
          <label htmlFor="name" className="form__label">
            Pass Code
          </label>
        </div>
        <div hidden={!checkPassCode(passCodeEntered) || !passCodeEntered}>
          <a className="btn primary" onClick={handleSubmit}>
            <span>USE PASS</span>
          </a>
        </div>
      </form>
    </div>
  );

  const handle_back = async (_event) => {
    await delay(300);
    window.location.replace(window.location.origin);
  };
  return (
    <div className="box center">
      <div className="back arrow" onClick={handle_back}>
        <div className="arrow-top"></div>
        <div className="arrow-bottom"></div>
      </div>
      {error ? error_frame : pass_code_input}
    </div>
  );
}
