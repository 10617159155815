/* eslint-disable no-unused-vars */
/* eslint-disable no-constant-condition */
/* eslint-disable react/prop-types */

import "./css/PassCreate.css";

import { React, useState, useEffect } from "react";
import { delay } from "./Utils";

const PassTypes = {
  Trial: "Trial",
  OneTime: "OneTime",
  Day: "Day",
};

export function PassCreate({ setIsLoading }) {
  const [passes, setPasses] = useState(null);
  // error message display
  let [error, setError] = useState(null);

  // if current pass is set, show current pass.
  let [currentPass, setCurrentPass] = useState(null);

  let [showUnlinked, setShowUnlinked] = useState(false);
  let [showVoid, setShowVoid] = useState(false);
  let [showTrial, setShowTrial] = useState(false);

  const updatePasses = () => {
    setIsLoading(true);
    fetch(
      "/gm/api/pass/get?show_unlinked=" +
        showUnlinked +
        "&show_void=" +
        showVoid +
        "&show_trial=" +
        showTrial
    )
      .then((response) => response.json())
      .then((data) => {
        setPasses(data.pass_records);
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    updatePasses();
  }, [showUnlinked, showVoid, showTrial]);

  // current pass frame
  const current_pass_frame = currentPass ? (
    <div className="frame pass_qr">
      <h1>{currentPass.pass_type} Pass</h1>
      <p>Click QR code to dismiss</p>
      <div
        onClick={() => {
          setCurrentPass(null);
        }}
      >
        <img className="qr_code" src={currentPass.qr_url} alt="pass qr" />
      </div>
      <p className="pass_code">{currentPass.pass_code}</p>
    </div>
  ) : null;

  // pass selector frame
  const create_pass = (pass_type) => {
    setIsLoading(true);
    fetch("/gm/api/pass/create?p_type=" + pass_type)
      .then((response) => {
        if (response.status == 200) {
          return response.json();
        } else {
          console.log("error resp", response);
        }
      })
      .then((data) => {
        setCurrentPass(data.pass);
        updatePasses();
      })
      .catch((e) => {
        console.log("error", e);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };
  const pass_selector_frame = currentPass ? null : (
    <div className="frame pass_selector">
      <h1>Create Pass</h1>
      <a
        className="btn secondary"
        onClick={() => {
          create_pass(PassTypes.Trial);
        }}
      >
        <span>TRIAL</span>
      </a>
      <a
        className="btn secondary"
        onClick={() => {
          create_pass(PassTypes.OneTime);
        }}
      >
        <span>ONE TIME</span>
      </a>
      <a
        className="btn primary"
        onClick={() => {
          create_pass(PassTypes.Day);
        }}
      >
        <span>DAY</span>
      </a>
    </div>
  );
  const void_pass = (p_id) => {
    fetch("/gm/api/pass/void?p_id=" + p_id)
      .then((response) => {
        if (response.status != 200) {
          console.log("error resp", response);
        } else {
          updatePasses();
        }
      })
      .catch((e) => {
        console.log("error", e);
      });
  };
  const pass_records_frame = (
    <table className="pass-records-table">
      <tbody>
        <tr>
          <th>#</th>
          <th>Create Time</th>
          <th>Type</th>
          <th>Code</th>
          <th>Linked To</th>
          <th>Is Valid</th>
          <th>Void</th>
        </tr>
        {passes
          ? passes.map((p, index) => (
              <tr key={p._id.$oid}>
                <td>{index + 1}</td>
                <td>
                  {new Date(p.created_time)
                    .toLocaleString("en-US", {
                      timeZone: "America/Los_Angeles",
                    })
                    .replace(",", "\n")}
                </td>
                <td>{p.pass_type}</td>
                <td>{p.pass_code}</td>
                <td>{p.used_by}</td>
                <td>{p.valid ? "Yes" : "No"}</td>
                <td>
                  {p.can_be_voided ? (
                    <a
                      className="btn void_btn"
                      onClick={() => {
                        void_pass(p._id.$oid);
                      }}
                    >
                      VOID
                    </a>
                  ) : null}
                </td>
              </tr>
            ))
          : null}
      </tbody>
    </table>
  );

  return (
    <div className="box">
      <div className="box center">
        {current_pass_frame}
        {pass_selector_frame}
      </div>
      <div className="frame">
        <h1>Pass Records</h1>
        <div className="pass_view_options">
          <label>
            <input
              type="checkbox"
              checked={showUnlinked}
              onChange={() => {
                setShowUnlinked(!showUnlinked);
              }}
            />
            <span>Unlinked</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={showVoid}
              onChange={() => {
                setShowVoid(!showVoid);
              }}
            />
            <span>Void</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={showTrial}
              onChange={() => {
                setShowTrial(!showTrial);
              }}
            />
            <span>Trial</span>
          </label>
        </div>
        <div className="scrollable">{pass_records_frame}</div>
      </div>
    </div>
  );
}
