/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import React from "react";
import {
  getAuth,
  GoogleAuthProvider,
  EmailAuthProvider,
  PhoneAuthProvider,
} from "firebase/auth";
import "firebase/compat/auth";

import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { FCM_APP } from "./App";

// Configure Firebase Login UI.
let signInOptions = [
  GoogleAuthProvider.PROVIDER_ID,
  {
    provider: EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD,
    requireDisplayName: false,
  },
  {
    provider: PhoneAuthProvider.PROVIDER_ID,
    recaptchaParameters: {
      type: "image", // 'audio'
      size: "normal", // 'invisible' or 'compact'
      // badge: "bottomleft", //' bottomright' or 'inline' applies to invisible.
    },
    loginHint: "+11234567890",
    whitelistedCountries: ["US", "+44"],
  },
];
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: signInOptions,
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};
function SignInScreen({ error }) {
  const service_unavailable = (
    <div className="frame tight error">
      <svg
        aria-hidden="true"
        className="w-6 h-6 text-red-500 dark:text-red-600"
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
        />
      </svg>
      <p>Service is not available at this time</p>
      <p>Please refresh page and try again</p>
    </div>
  );

  const sign_in_frame = (
    <div className="frame tight">
      <h1>Sign In</h1>
      <p className="p">Sign in to view your account.</p>
      <StyledFirebaseAuth
        className="firebase_ui"
        uiConfig={uiConfig}
        firebaseAuth={FCM_APP.auth()}
      />
    </div>
  );

  return (
    <div className="box full">
      <h2>MetaArms Player Hub</h2>
      {error ? service_unavailable : sign_in_frame}
    </div>
  );
}

export { SignInScreen };
